<template>
  <b-card>

    <div
      class="flex-between text-right"
      style="margin-bottom: 10px"
    >
      <div class="flex-start">
        <div class=" filter-item">
          <el-input
            v-model="listQuery.searchStr"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
        </div>
        <el-button
          size="small"
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <el-button
        style="margin-bottom: 10px;"
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="$router.push(`${savePath}/0/${templateType}/1`)"
      >
        新增
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      fit
      border
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="code"
        label="模版编号"
      />
      <el-table-column
        prop="templateName"
        :show-overflow-tooltip="true"
        label="模版名称"
      />
      <el-table-column
        v-if="templateType === 2"
        prop="language"
        :show-overflow-tooltip="true"
        label="语言"
      />
      <el-table-column
        v-if="templateType === 2 || templateType === 1"
        prop="area"
        :show-overflow-tooltip="true"
        label="地区"
      />
      <el-table-column
        prop="description"
        :show-overflow-tooltip="true"
        label="模版说明"
      />
      <el-table-column
        prop="status"
        label="状态"
      >
        <template slot-scope="{row}">
          {{ row.status === 1 ? '编辑中' : '已完成'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="updateName"
        label="编辑人"
      />
      <el-table-column
        prop="updateTime"
        label="更新时间"
      />
      <el-table-column
        label="操作"
        width="200"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            :underline="false"
            type="primary"
            @click="$router.push(`${savePath}/${row.code}/${templateType}/3`)"
          >
            <el-tooltip
              content="复制"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document-copy" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="$router.push(`${savePath}/${row.code}/${templateType}/2`)"
          >
            <el-tooltip
              content="修改"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit-outline" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="preview(row)"
          >
            <el-tooltip
              content="预览"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="deleteTemplate(row)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getTemplatePage"
    />
  </b-card>
</template>

<script>
import { DeleteTemplateById, GetTemplateListByPage } from '@/api/knowledgeBase/template'
import { error, success } from '@core/utils/utils'
import pagination from '@core/components/pagination/Pagination'

export default {
  components: {
    pagination,
  },
  props: {
    templateType: {
      type: Number,
    },
  },
  data() {
    const pathTypeObj = {
      2: 'statement',
      1: 'policy',
      4: 'collect',
    }
    console.log(`/template/${pathTypeObj[this.templateType]}/content`)
    return {
      savePath: `/template/${pathTypeObj[this.templateType]}/content`,
      queryData: {},
      total: 0,
      tableData: [],
      listLoading: false,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
        templateType: this.templateType,
      },
    }
  },
  created() {
    this.getTemplatePage()
  },

  methods: {
    preview(row) {
      const routeUrl = this.$router.resolve({
        path: '/template-preview',
        query: { id: row.code },
      })
      window.open(routeUrl.href, '_blank')
    },
    getTemplatePage() {
      GetTemplateListByPage(this.listQuery).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      })
    },
    deleteTemplate(data) {
      this.$confirm('是否确认删除当前模版吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const codes = []
        codes.push(data.code)
        DeleteTemplateById({ codes }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.getTemplatePage()
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getTemplatePage()
    },
  },
}
</script>
